
import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsHeader',
  props: {
    title: { type: String, required: true },
    patientNo: { type: String, required: false },
    showIcon: { type: Boolean, required: false, default: false },
  },
})
