<template>
  <v-card class="pb-4" tile>
    <BoxedDiv>
      <v-card-text>
        <slot />
      </v-card-text>
    </BoxedDiv>
  </v-card>
</template>

<script>
import BoxedDiv from '@/components/layout/BoxedDiv'
export default {
  name: 'BoxedCard',
  components: { BoxedDiv },
}
</script>
