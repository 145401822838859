<template>
  <v-dialog v-model="showDialog" fullscreen data-testid="edit-patient-id">
    <template #activator="{ on }">
      <v-btn
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
      >
        Activate app
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="activate-app-card-title"
      >
        Changing app settings for patient {{ patientNo }}
      </BoxedToolbar>

      <BoxedCard>
        <SettingsHeader
          :patient-no="patientNo"
          :title="`Activate app for patient ${patientNo}`"
          :show-icon="true"
        >
          <template #icon>
            <ImgActivateApp />
          </template>
          <template #text> Set up the app for the patient </template>
        </SettingsHeader>

        <v-form v-model="formValid" ref="editForm">
          <v-row class="no-gutters">
            <v-col class="offset-4 col-5">
              <FormLabel> Select language for patient app </FormLabel>

              <v-autocomplete
                :items="appLanguageOptionsSorted"
                item-text="name"
                item-value="languageTag"
                v-model="appLanguage"
                @change="addAuditEntry('APP_SETTINGS:LANGUAGE', $event)"
                outlined
                placeholder="Please select"
                :rules="appLanguageRules"
                data-testid="app-language"
                class="rounded-lg"
                :menu-props="{
                  maxHeight: 400,
                }"
              />

              <FormLabel>
                Select the time format for the patient app
              </FormLabel>

              <v-radio-group
                v-model="appTimeFormat"
                :rules="appTimeFormatRules"
                data-testid="select-app-time-format"
                class="boxed-radio-group"
                @change="addAuditEntry('APP_SETTINGS:TIME_FORMAT', $event)"
                ><span
                  v-for="(option, index) in appTimeFormatOptions"
                  :key="index"
                  class="radio-option"
                  :class="{
                    selected: appTimeFormat === option.value,
                  }"
                >
                  <v-radio
                    :label="option.text"
                    :value="option.value"
                    :data-testid="`app-time-format-option-${option.value}`"
                  />
                </span>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="no-gutters mt-8 pr-4">
            <v-col class="text-right">
              <v-btn @click="closeDialog" text class="btn-cancel-reset-pin mr-4"
                ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
                settings</v-btn
              >
              <v-btn
                @click="callApiCreateApp"
                :disabled="!formValid"
                :loading="isLoading"
                color="secondary"
                rounded
                class="elevation-1"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="apiError">
            <v-col class="offset-4 col-8">
              <v-alert type="error" dense text class="mb-0">{{
                apiError
              }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </BoxedCard>
      <v-dialog v-model="showConfirmDialog" max-width="600" persistent>
        <DialogCard
          v-if="currentStep === activateSteps.DONE"
          @closeDialog="closeDialog"
        >
          <template #title>App activation successful</template>
          <template #default>
            <v-row>
              <v-spacer></v-spacer>
              <ImgAppSetupSuccess />
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <h4 class="mt-4">Success!</h4>
                The patient app and the<br />
                ePID Dashboard are connected!
              </v-col>
            </v-row>
          </template>
          <template #actions>
            <v-btn
              rounded
              depressed
              class="elevation-1 btn-close"
              color="secondary"
              @click.prevent="closeDialog"
              >Close</v-btn
            >
          </template>
        </DialogCard>

        <DialogCard v-else @closeDialog="closeDialog">
          <template #title>Configure patient {{ patientNo }} ePID app</template>
          <template #default>
            <AppQrCode
              :loading="currentStep === activateSteps.LOADING_QR"
              :data-for-qr-code="dataForQrCode"
            />
            <v-alert v-if="apiError" type="error" dense text class="my-2">{{
              apiError
            }}</v-alert>
          </template>
          <template #actions>
            <v-btn
              rounded
              depressed
              class="mr-4 elevation-1 btn-cancel"
              color="default"
              @click="closeDialog"
              >Cancel</v-btn
            >
          </template>
        </DialogCard>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import SettingsHeader from '../settings/_SettingsHeader'
import ImgActivateApp from '@/components/images/ImgActivateApp'
import loadingState from '@/constants/loadingState'
import service from '@/services/app-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import AuditLog from '@/utils/audit/AuditLog'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel'
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'
import trialLanguagesMixin from '../trialLanguagesMixin'
import DialogCard from '@/components/DialogCard'
import ImgAppSetupSuccess from '@/components/images/ImgAppSetupSuccess'
import AppQrCode from '@/components/patient/add-patient/init-app/AppQrCode'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import BoxedCard from '@/components/patient/settings/BoxedCard'

const initialState = () => ({
  appLanguage: null,
  appTimeFormat: null,
  dataForQrCode: null,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
  apiError: null,
  currentStep: activateSteps.INPUT_SETTINGS,
})

const activateSteps = {
  INITIAL: 1,
  INPUT_SETTINGS: 2,
  LOADING_QR: 3,
  QR_LOADED: 4,
  LOAD_ERRORED: 5,
  WAIT_FOR_DEVICE: 6,
  DONE: 7,
}
Object.freeze(activateSteps)

export default {
  name: 'ActivateAppSteps',
  mixins: [trialLanguagesMixin],
  components: {
    BoxedCard,
    BoxedToolbar,
    AppQrCode,
    ImgAppSetupSuccess,
    DialogCard,
    FormLabel,
    ImgActivateApp,
    SettingsHeader,
  },
  props: {
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      appTimeFormatOptions,
      appLanguageRules: [v => !!v || 'App language is required'],
      appTimeFormatRules: [v => !!v || 'Patient sex is required'],
      activateSteps,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
      this.$emit('closed')
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    callApiCreateApp() {
      this.currentStep = activateSteps.LOADING_QR
      this.state = loadingState.LOADING
      this.apiError = null

      const data = {
        patientNumber: this.patientNo,
        languageTag: this.appLanguage,
        clockNotation: this.appTimeFormat,
        currentClientTime: dateTimeWithTimeZone(),
        auditEntries: this.auditLog.getAuditEntries(),
      }

      service
        .createApp(data)
        .then(response => {
          this.dataForQrCode = JSON.stringify(response)
          this.currentStep = activateSteps.QR_LOADED
          this.showConfirmDialog = true
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.apiError = error

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>
